<template>
  <div class="card">

    <slot name="header"/>

    <div
      v-if="!hideHeader"
      :class="`card-header card-header-with-toggle-btn ${headerClasses}`"
    >
      <slot name="header-left">
        <div class="card-title">{{ title }}</div>
      </slot>
      <slot name="header-right"></slot>
    </div>

    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultCard',
  props: {
    title: {
      type: String,
      default: 'Card Title'
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    headerClasses: {
      type: String,
      default: ''
    },
  }
}
</script>

<style scoped>

</style>
