<template>
  <XyzTransitionGroup
    :tag="tag"
    :xyz="animateClasses.join(' ')"
    :appear="initialAnimation"
  >
    <slot></slot>
  </XyzTransitionGroup>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'StackedAnimation',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    duration: {
      type: [Number, String],
      validator: (value) => {
        const allowed = [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
        if (allowed.includes(parseFloat(value))) return true;

        throw new Error(`Unsupported animation duration '${value}', allowed: ${allowed}`);
      }
    },
    initialAnimation: {
      type: Boolean,
      default: true
    },
    stackDuration: {
      type: [Number, String],
      validator: value => {
        const allowed = [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
        if (allowed.includes(parseFloat(value))) return true;

        throw new Error(`Unsupported stack duration '${value}', allowed: ${allowed}`);
      }
    },
    stackReverseOrder: {
      type: Boolean,
      default: false
    },
    directionIn: {
      type: String,
      default: 'down',
      validator: value => {
        const allowed = ['right', 'left', 'down', 'up', 'front', 'back'];

        if (allowed.includes(value)) return true;
        throw new Error(`Invalid direction '${value}', allowed: ${allowed}`);
      }
    },
    directionOut: {
      type: String,
      validator: value => {
        const allowed = ['right', 'left', 'down', 'up', 'front', 'back'];

        if (allowed.includes(value)) return true;
        throw new Error(`Invalid direction '${value}', allowed: ${allowed}`);
      }
    },
    directionScale: {
      type: String,
      validator: value => {
        const allowed = ['0', '1', '2', '3', '4', '5', '25%', '50%', '75%', '100%'];

        if (allowed.includes(value)) return true;
        throw new Error(`Invalid direction Scale '${value}', allowed: ${allowed}`);
      }
    },
    additionalAnimateClasses: {
      type: String,
    }
  },

  setup (props) {

    const animationDuration = computed(() => {
      let duration = 'duration';

      if (props.duration === undefined) return duration;

      duration += `-${props.duration}`;
      return duration;
    });

    const animationDirection = computed(() => {

      const inDirection = `in-${props.directionIn}`;
      const outDirection = `out-${props.directionOut === undefined ? props.directionIn : props.directionOut}`;

      let direction = [inDirection, outDirection];
      if (props.directionScale === undefined) return direction;

      direction[0] = `${inDirection}-${props.directionScale}`;
      direction[1] = `${outDirection}-${props.directionScale}`;
      return direction;
    });

    const animateClasses = computed(() => {

      const stack = props.stackReverseOrder ? 'stagger-rev' : 'stagger';

      const stackWithTiming = props.stackDuration === undefined
        ? stack
        : `${stack}-${props.stackDuration}`
      ;

      return [
        'fade',
        stackWithTiming,
        animationDuration.value,
        ...animationDirection.value,
        props.additionalAnimateClasses
      ];
    });

    return {
      animateClasses
    };
  }
};
</script>

<style scoped>
</style>
