<template>
  <DefaultCard
    hide-header
    :class="`h-100 border ${selectedOrder.id === order.id ? 'border-primary':''}`"
  >
    <template #header>
      <div
        :class="`card-header card-header-with-toggle-btn ${selectedOrder.id === order.id ? 'active':''} border-bottom border-primary`"
        @click="selectOrder(order)"
      >
        <div>{{ order.customer_name }}</div>
        <div>
          <i class="icon-pin menu-pin-btn" />
        </div>
      </div>
    </template>

    <OrderMenuDetails
      :order="order"
      :use-big-font="fullscreenView"
    />
  </DefaultCard>
</template>

<script>
import DefaultCard from '@/components/Util/card/DefaultCard';
import OrderMenuDetails from '@/components/OrderMenu/OrderMenuDetails';

export default {
  name: 'OrderMenuCard',
  components: { OrderMenuDetails, DefaultCard },
  emits: ['orderSelect'],
  props: {
    order: {
      type: Object,
      required: true
    },
    selectedOrder: {
      type: Object,
      required: true
    },
    fullscreenView: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { emit }) {

    const selectOrder = (order) => {
      emit('orderSelect', order);
    };

    return {
      selectOrder,
    };
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/variables";

.menu-pin-btn {
  font-size: 30px !important;
  border-radius: 5px;
  cursor: pointer;
  padding: 2px;
  border: 0;
}

.card-header {
  cursor: pointer;
  background-color: #e4e4e4;

  &.active {
    background-color: $primary;
    color: $light;
  }
}

.border {
  border-color: transparent !important;

  &.border-primary {
    border-color: $primary !important;
  }
}
</style>
