<template v-if="items.length">
  <template
    v-for="item in items"
    :key="item.id"
  >
    <!--item-->
    <tr
      :style="{
        fontSize: itemFontSize
      }"
    >
      <td>{{ hideQty ? '' : item.qty }}</td>
      <td>{{ item.name }}</td>
      <td v-if="showPrice">{{ currency(item.price) }}</td>
    </tr>

    <!--addons-->
    <tr
      v-for="addon in item.addons"
      :key="`adn-${addon.id}`"
      :style="{
        fontSize: addonFontSize
      }"
    >
      <td></td>
      <td class="text-primary">{{ addon.name }}</td>
      <td
        v-if="showPrice"
        class="text-primary"
      >
        {{ currency(addon.price) }}
      </td>
    </tr>
  </template>
</template>

<script>
import { currency } from '@/Mixins/appHelper';

export default {
  name: 'ItemListWithAddons',
  props: {
    items: {
      type: Array,
      required: true
    },
    hideQty: {
      type: Boolean,
      default: false
    },
    showPrice: {
      type: Boolean,
      default: false
    },
    itemFontSize: {
      type: String,
      default: '18px'
    },
    addonFontSize: {
      type: String,
      default: '14px'
    }
  },

  setup () {

    return {
      currency,
    };
  }
};
</script>

<style scoped>

</style>
