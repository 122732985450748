<template>
  <DefaultCard hide-header>
    <div class="d-flex justify-content-between">
      <div class="orders-count-txt">
        <strong>{{ state.orders.length }}</strong> orders to process
      </div>
      <div class="d-flex align-items-center">
        <span class="me-2">Fullscreen View</span>
        <i
          class="icon-resize-full-screen full-screen-btn"
          @click="state.showFullscreenPopUp = true"
        />
      </div>
    </div>
  </DefaultCard>

  <StackedAnimation
    v-if="!state.loading"
    class="row mt-5"
    stack-duration="1"
  >
    <div
      v-for="(order) in state.orders"
      :key="order.id"
      class="col-sm-12 col-md-6 col-lg-4 col-xl-3 details-container"
    >
      <OrderMenuCard
        :order="order"
        :selected-order="state.pinnedOrder"
        @orderSelect="selectOrder"
      />
    </div>
  </StackedAnimation>

  <div
    v-else
    class="mt-5"
  >
    <Spinner />
  </div>

  <!--full screen popup-->
  <OrderMenuListPopUp
    v-model="state.showFullscreenPopUp"
    :orders="state.orders"
    :selected-order="state.pinnedOrder"
    @orderSelect="selectOrder"
  />

</template>

<script>
import DefaultCard from '@/components/Util/card/DefaultCard';
import { computed, onMounted, reactive } from 'vue';
import { orderStatuses } from '@/utils/Helper';
import { useStore } from 'vuex';
import Spinner from '@/components/Util/Spinner';
import OrderMenuListPopUp from '@/components/OrderMenu/OrderMenuListPopUp';
import OrderMenuCard from '@/components/OrderMenu/OrderMenuCard';
import StackedAnimation from '@/components/Util/Animations/StackedAnimation';

export default {
  name: 'OrderMenu',
  components: { StackedAnimation, OrderMenuCard, OrderMenuListPopUp, Spinner, DefaultCard },

  setup () {
    const store = useStore();

    const state = reactive({
      orders: computed(() => {
        const orders = store.getters['dashboard/orders'];

        // exclude cancelled & delivered orders
        const excludeOrderStatuses = [orderStatuses.processing];
        return orders.filter(order => excludeOrderStatuses.includes(order.status));
      }),
      loading: computed(() => store.state.dashboard.loading),
      pinnedOrder: {},

      showFullscreenPopUp: false,
    });

    const selectOrder = (order) => {
      state.pinnedOrder = order;
    };

    onMounted(async () => {

      // when data not present fetch it
      if (!state.orders.length) {
        await store.dispatch('dashboard/getDashboardContent');
      }

    });

    return {
      state,
      selectOrder,
    };
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/sass/variables";

.orders-count-txt {
  font-size: 19px;
}

.full-screen-btn {
  font-size: 30px !important;
  padding: 5px;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  cursor: pointer;
}

.details-container {
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>
